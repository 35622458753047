import React, { useState, useCallback, useEffect } from "react";
import GenericTable from "../../components/common/GenericTable";
import DynamicForm from "../../components/common/DynamicForm";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { GetAllData } from "../../api/ApiDataServiceCategory";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading";

const AirportTerminalPage = () => { 
    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const addItemApiUrl = ApiURL.CreateAirportTerminal;
    const editItemApiUrl = ApiURL.EditAirportTerminal;
    const deleteItemApiUrl = ApiURL.DeleteAirportTerminal;
    const getAllItemApiUrl = ApiURL.GetAllAirportTerminals;

    // Table columns
    const columns = [
      { key: "TerminalId", alias: "#", isPrimaryKey: true, isVisible: false },    
      { key: "TerminalName", alias: "Terminal Name", colWidth: '400px' },
    ];

    // Ignore columns from search text
    const ignoredColumns = ["TerminalId"];

    // Blank object for add item
    const addingObject = {
      TerminalId: "", //PK
      TerminalName: "",     
    };

    // Data model for add/edit item
    const formDataModel = [
    {
      name: "TerminalId",
      label: "Id",
      type: "text",
      required: false,
      isPrimaryKey: true,
      isReadonly: true,
      isHidden: true,
      showLabel: true,
    },
    {
      name: "TerminalName",
      label: "Terminal Name",
      type: "text",
      required: true,
      showLabel: true,
    },
    // {
    //     name: "IsActive",
    //     label: "Is Active",
    //     type: "checkbox",
    //     required: false,
    //     showLabel: false,
    // },
    ];

  
    const getAllAirportTerminalsData = () => {
       GetAllData(getAllItemApiUrl)
      .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
          setInitialData(json.DataResult);
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        setServiceError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    };

    useEffect(() => {
        setLoading(true);        
        getAllAirportTerminalsData();
    }, [serviceError]);
    
    return (              
      <div className="text-dark">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-info font-weight-lighter">
            Terminals
          </h4>
        </div>

        {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
        {loading ? (
            <Loading />
        ) : serviceError ? (
          <ServiceUnavailable />
        ) : (
          <>
            {initialData && (
              <GenericTable
                data={initialData}
                columns={columns}
                ignoredColumns={ignoredColumns}
                enablePaging={true}
                enableSorting={true}
                enableSearching={true}
                enableDeleting={true}
                addingObject={addingObject}
                addTitle="Add Terminal"
                editTitle="Edit Terminal"
                addItemApiUrl={addItemApiUrl}
                editItemApiUrl={editItemApiUrl}
                deleteItemApiUrl={deleteItemApiUrl}
                getAllItemApiUrl={getAllItemApiUrl}
                AddItemComp={DynamicForm}
                EditItemComp={DynamicForm}
                formDataModel={formDataModel}
                noOfColumnsLayout={1}
                pageSizeOptions={[10, 50, 100]}
              />
            )}
          </>
        )}
      </div> 
  );

}

export default AirportTerminalPage;