class ApiURL {
  ////==========================================================================
  ////Base URL Section
  ////==========================================================================
  ////Local 
  //static BaseURL = "https://localhost:7263"; 

  ////Production
  static BaseURL = "https://service.myboogyairporttransfer.co.uk";

  ////==========================================================================
  ////Image URL Section
  ////==========================================================================
  static BaseImageURL = this.BaseURL + "/uploadfiles/images";
  static HomeBannerImageURL = this.BaseImageURL + "/HomeBanner";
  static HomeDestinationImageURL = this.BaseImageURL + "/HomeDestination";
  static PackagesWeOfferImageURL = this.BaseImageURL + "/HomePackageWeOffer";
  static TestimonialClientImageURL = "https://mdbcdn.b-cdn.net/img/Photos/Avatars";
  //static TestimonialClientImageURL = this.BaseImageURL + "/Testimonial";
  static HotPackageImageURL = this.BaseImageURL + "/HotPackages";
  static PackageTopBannerImageURL = this.BaseImageURL + "/Packages/TopBanners";
  static PackageDestinationBannerImageURL = this.BaseImageURL + "/Packages/Destinations";
  static HotelImageURL = this.BaseImageURL + "/Packages/Hotels";

 

  ////===========================================================================
  ////API URL Section
  ////===========================================================================
  static SaveCustomerEnquery = this.BaseURL + "/api/customer/saveCustomerEnquery";
  static GetAllOriginCities = this.BaseURL + "/api/customer/getAllOriginCities";
  static GetAllURL = this.BaseURL + "/api/url/getAllURL";
  //static AdminLogin = this.BaseURL + "/api/account/adminLogin";

  //Service Categories
  static GetAllServiceCategories = this.BaseURL + "/api/servicecategory/categories";
  static GetServiceCategoriesSearch = this.BaseURL + "/api/servicecategory/categorysearch";
  static CreateServiceCategory = this.BaseURL + "/api/servicecategory/create";
  static EditServiceCategory = this.BaseURL + "/api/servicecategory/update/{id}";
  static DeleteServiceCategory = this.BaseURL + "/api/servicecategory/delete/{id}";

  //Services
  static GetAllServicesApiUrl = this.BaseURL + "/api/service/allservices";
  static CreateServiceApiUrl = this.BaseURL + "/api/service/create";
  static EditServiceApiUrl = this.BaseURL + "/api/service/update/{id}";
  static DeleteServiceApiUrl = this.BaseURL + "/api/service/delete/{id}";

  //Providers
  static GetAllProviders = this.BaseURL + "/api/provideradmin/providers";
  static ApproveOrRejectProvider = this.BaseURL + "/api/provideradmin/approveOrReject";

  //Admin Login
  static AdminLoginApiUrl = this.BaseURL + "/api/auth/adminLogin";

  //Airport Terminals
  static GetAllAirportTerminals = this.BaseURL + "/api/AirportTerminal/terminals";
  static GetAirportTerminalsSearch = this.BaseURL + "/api/AirportTerminal/search"; //Not implemented
  static CreateAirportTerminal = this.BaseURL + "/api/AirportTerminal/create";
  static EditAirportTerminal = this.BaseURL + "/api/AirportTerminal/update/{id}";
  static DeleteAirportTerminal = this.BaseURL + "/api/AirportTerminal/delete/{id}";

  //Car Operators
  static GetCarOperators = this.BaseURL + "/api/CarOperator/operators";
  static GetCarOperatorsSearch = this.BaseURL + "/api/CarOperator/search"; //Not implemented
  static CreateCarOperator = this.BaseURL + "/api/CarOperator/create";
  static EditCarOperator = this.BaseURL + "/api/CarOperator/update/{id}";
  static DeleteCarOperator = this.BaseURL + "/api/CarOperator/delete/{id}";
  static GetAllCarOperators = this.BaseURL + "/api/CarOperator/alloperators";
  
  //Car Type
  static GetCarTypes = this.BaseURL + "/api/CarType/types";
  static GetCarTypesSearch = this.BaseURL + "/api/CarType/search"; //Not implemented
  static CreateCarType = this.BaseURL + "/api/CarType/create";
  static EditCarType = this.BaseURL + "/api/CarType/update/{id}";
  static DeleteCarType = this.BaseURL + "/api/CarType/delete/{id}";
  static GetAllCarTypes = this.BaseURL + "/api/CarType/allcartypes";
  
  //Partner
  static GetAllPartners = this.BaseURL + "/api/Partner/partners";
  static GetPartnersSearch = this.BaseURL + "/api/Partner/search"; //Not implemented
  static CreatePartner = this.BaseURL + "/api/Partner/create";
  static EditPartner = this.BaseURL + "/api/Partner/update/{id}";
  static DeletePartner = this.BaseURL + "/api/Partner/delete/{id}";
  
  //Postcode District
  static GetAllPostcodeDistricts = this.BaseURL + "/api/PostcodeDistrict/postcodes";
  static GetPostcodeDistrictsSearch = this.BaseURL + "/api/PostcodeDistrict/search"; //Not implemented
  static CreatePostcodeDistrict = this.BaseURL + "/api/PostcodeDistrict/create";
  static EditPostcodeDistrict = this.BaseURL + "/api/PostcodeDistrict/update/{id}";
  static DeletePostcodeDistrict = this.BaseURL + "/api/PostcodeDistrict/delete/{id}"; 
  static GetAllPostcodes = this.BaseURL + "/api/PostcodeDistrict/allpostcodes";

  //Track Bookings
  static GetAllBookings = this.BaseURL + "/api/booking/postgetAllBookings";
  static ApproveOrRejectBookings = this.BaseURL + "/api/booking/cancelBooking";
  static AddBooking = this.BaseURL + "/api/booking/addBookingDetails";
  static EditBooking = this.BaseURL + "/api/booking/update/{id}";

  //Booking Rates
  static GetPostCodeToTerminalRate = this.BaseURL + "/api/PostCodeTerminalRate/postCodeToTerminalRate";
  static UpdatePostCodeToTerminalRates = this.BaseURL + "/api/PostCodeTerminalRate/updatePostCodeToTerminalRates";
  
}

export default ApiURL;
