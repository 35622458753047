import React from "react";
import DashboardKPI from "../components/home/DashboardKPI";

const HomePage = () => {
  return (
    <DashboardKPI />    
  );
}

export default HomePage;
