import React, { useEffect } from 'react'
import CustomDropdown from '../../components/common/CustomDropdown'
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import cogoToast from "cogo-toast";
import SimpleDropdown from '../../components/common/SimpleDropdown';
import ApiURL from "../../api/ApiURL";
import { GetAllData, GetAllDataByPostMethodBody } from "../../api/ApiDataServiceCategory";

const ManageBookingRate = () => {
    const [loading, setLoading] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const allPostCodesApiUrl = ApiURL.GetAllPostcodes;
    const allCarOperatorsApiUrl = ApiURL.GetAllCarOperators;
    const allCarTypeApiUrl = ApiURL.GetAllCarTypes;
    const getPostCodeToTerminalRateApiUrl = ApiURL.GetPostCodeToTerminalRate;
    const updatePostCodeToTerminalRatesApiUrl = ApiURL.UpdatePostCodeToTerminalRates;
    const [dbOperationStatus, setDbOperationStatus]  = useState(false);
    

    const [allPostCodes, setAllPostCodes] = useState([]);
    const [mappedAllPostCodes, setMappedAllPostCodes] = useState([]);

    const [allCarOperators, setAllCarOperators] = useState([]);
    const [mappedAllCarOperators, setMappedAllCarOperators] = useState([]);

    const [allCarTypes, setAllCarTypes] = useState([]);
    const [mappedAllCarTypes, setMappedAllCarTypes] = useState([]);

    // const [selectedValueForPostCode, setSelectedValueForPostCode] = useState('');
    // const [selectedTextForPostCode, setSelectedTextForPostCode] = useState('');

    const [selectedPostCode, setSelectedPostCode] = useState({label:'', value:''});
    const [selectedCarOperator, setSelectedCarOperator] = useState({label:'', value:''});
    const [selectedCarType, setSelectedCarType] = useState({label:'', value:''});
    const [terminalRateRecords, setTerminalRateRecords] = useState([]);
    
    const handleRateChange = (index, newRate) => {
        const updatedRecords = [...terminalRateRecords];
        updatedRecords[index].Rate = newRate;
        setTerminalRateRecords(updatedRecords);
    };

    //! ----------- Finnal Submit----------
    const updatePostCodeToTerminalRates = () => {
        
        if(terminalRateRecords && terminalRateRecords.length>0 && (terminalRateRecords[0].PostCodeId 
            || terminalRateRecords[0].OperatorId 
            ||  terminalRateRecords[0].CarId)){
            GetAllDataByPostMethodBody(updatePostCodeToTerminalRatesApiUrl, terminalRateRecords)
            .then((json) => {
            setServiceError(false);
            if (json.StatusCode === 200) {           
                //console.log('updatePostCodeToTerminalRates response:', json.DataResult);
                setDbOperationStatus(json.DataResult);           
                cogoToast.success("Successfully submitted", {
                    position: "bottom-center",
                });
            } else if (json.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", {
                position: "bottom-center",
                });
            } else {
                cogoToast.error("Request Failed! Please Try Again.", {
                position: "bottom-center",
                });
            }
            })
            .catch((error) => {
            setServiceError(true);
            })
            .finally(() => {
            setLoading(false);
            });
        }
        else{
            cogoToast.warn("Please search Post Code, Operator and Car Type properly, then try again.", {
                position: "bottom-center",
                });
        }

        
    }

    const handleSubmit = () => {
        // console.log("Updated Records:", terminalRateRecords);     
        updatePostCodeToTerminalRates();
    };

    // ! PostCode ------------------
    const postCodeOptions = [...mappedAllPostCodes]
     
    const handleSelectedValueForPostCode = (selectedValue, selectedText) =>{   
        setSelectedPostCode({value: selectedValue, label: selectedText});     
    }

    // ! Operator ------------------
    const operatorOptions = [...mappedAllCarOperators]
   
    const handleSelectedValueForOperator = (selectedValue, selectedText) =>{        
        setSelectedCarOperator({value: selectedValue, label: selectedText});
    }

    // ! Car ------------------
    const carOptions = [...mappedAllCarTypes]
   
    const handleSelectedValueForCar = (selectedValue, selectedText) =>{  
        setSelectedCarType({value: selectedValue, label: selectedText});
    }

    const getPostCodeToTerminalRate = () => {
        let reqBody = {
            PostCodeId:selectedPostCode.value,
            OperatorId:selectedCarOperator.value,
            CarId: selectedCarType.value
        };

        GetAllDataByPostMethodBody(getPostCodeToTerminalRateApiUrl, reqBody)
        .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
            console.log('getPostCodeToTerminalRate response:', json.DataResult);
            let mappedRecords = [];
            json.DataResult.map((item, index)=>{
                mappedRecords.push({
                    Car:item.Car, 
                    CarId: item.CarId,
                    Operator: item.Operator,
                    OperatorId: item.OperatorId,
                    PostCode: item.PostCode,
                    PostCodeId: item.PostCodeId,
                    TerminalId: item.TerminalId,
                    TerminalName: item.TerminalName,
                    Rate: item.Rate})
            });        

            setTerminalRateRecords(mappedRecords);

        } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
            });
        } else {
            cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
            });
        }
        })
        .catch((error) => {
        setServiceError(true);
        })
        .finally(() => {
        setLoading(false);
        });
    }

    // ! Search
    const handleSearch = () =>{
        getPostCodeToTerminalRate();
    }


    const getAllPostcodes = () => {      
        GetAllData(allPostCodesApiUrl+ `?isActiveOnly=${true}`)
        .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
            setAllPostCodes(json.DataResult);             
            let mappedPostCodes = [];
            json.DataResult.map((item, index)=>{
            mappedPostCodes.push({value:item.PostCodeId, label: item.PostCode + '-' + item.LocationName})
            });
            setMappedAllPostCodes(mappedPostCodes);

        } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
            });
        } else {
            cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
            });
        }
        })
        .catch((error) => {
        setServiceError(true);
        })
        .finally(() => {
        setLoading(false);
        });
    };

    const getAllCarOperators = () => {      
        GetAllData(allCarOperatorsApiUrl+ `?isActiveOnly=${true}`)
        .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
            setAllCarOperators(json.DataResult);             
            let mappedData = [];
            json.DataResult.map((item, index)=>{
                mappedData.push({value:item.OperatorId, label: item.OperatorName})
            });
            setMappedAllCarOperators(mappedData);

        } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
            });
        } else {
            cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
            });
        }
        })
        .catch((error) => {
        setServiceError(true);
        })
        .finally(() => {
        setLoading(false);
        });
    };

    const getAllCarTypes = () => {      
        GetAllData(allCarTypeApiUrl+ `?isActiveOnly=${true}`)
        .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
            setAllCarTypes(json.DataResult);             
            let mappedData = [];
            json.DataResult.map((item, index)=>{
                mappedData.push({value:item.CarId, label: item.CarName})
            });
            setMappedAllCarTypes(mappedData);

        } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
            });
        } else {
            cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
            });
        }
        })
        .catch((error) => {
        setServiceError(true);
        })
        .finally(() => {
        setLoading(false);
        });
    };

    
    useEffect(()=>{ 
        getAllPostcodes();    
        getAllCarOperators();  
        getAllCarTypes();
    },[dbOperationStatus])


    return (
    <div className="text-dark">
    <div className="d-flex justify-content-between align-items-center">
      <h4 className="text-info font-weight-lighter">
        Booking Rates
      </h4>
    </div>
    <div className='d-flex flex-row justify-content-center align-items-center px-2 mr-2'>
        <div>
            {/* Post Code  */}
            <span className='mx-2'>Post Code</span> 
            <SimpleDropdown options={postCodeOptions} placeholder={"Post Code"} getSelectedValue={handleSelectedValueForPostCode} />
             
        </div>
        <div>
            {/* Operator */}
            <span className='mx-2'>Operator</span> 
            <SimpleDropdown options={operatorOptions} placeholder={"Operator"} getSelectedValue={handleSelectedValueForOperator} />
            
        </div>
        <div>
            {/* Car */}
            <span className='mx-2'>Car Type</span>
            <SimpleDropdown options={carOptions} placeholder={"Car"} getSelectedValue={handleSelectedValueForCar} />
            
            
        </div>
        <div>
            <div className='mx-2 mt-4'>
            <Button variant="primary" onClick={handleSearch} className='btn-sm btn-info small-font py-2 px-4'>
                <i className="fa fa-search" aria-hidden="true"></i> Search
            </Button>
            </div>
        </div>
    </div>
    <div className='d-flex flex-column justify-content-center align-items-center px-2 mr-2 shadow-sm p-3 mb-5 bg-white rounded table-responsive'>
        {terminalRateRecords && terminalRateRecords.length===0 && (
            selectedPostCode.value=== undefined || selectedCarOperator.value=== undefined || selectedCarType.value=== undefined)
             && <>No records to Add/Edit</>}
    
        {terminalRateRecords && terminalRateRecords.length>0 
        && selectedPostCode.value 
        && selectedCarOperator.value 
        && selectedCarType.value 
        && <>
        <table cellPadding="10" cellSpacing="0" className="table table-hover">
        <thead>
          <tr>
            <th>Post Code</th>
            <th>Operator</th>
            <th>Car</th>
            <th>Terminal</th>
            <th>Rate</th>
          </tr>
        </thead>
        <tbody>
          {terminalRateRecords.map((record, index) => (
            <tr key={index}>              
              <td>{record.PostCode}</td>
              <td>{record.Operator}</td>
              <td>{record.Car}</td>
              <td>{record.TerminalName}</td>
              <td>
                <input
                  type="number"
                  value={record.Rate}
                  onChange={(e) => handleRateChange(index, Number(e.target.value))}
                />
              </td>
              {/* Hidden columns */}
              <td style={{ display: "none" }}>{record.CarId}</td>
              <td style={{ display: "none" }}>{record.OperatorId}</td>
              <td style={{ display: "none" }}>{record.PostCodeId}</td>
              <td style={{ display: "none" }}>{record.TerminalId}</td>
            </tr>
          ))}
        </tbody>
        </table>
        <div className='mx-auto mx-2 mt-4'>
            <Button variant="primary" onClick={handleSubmit} className='btn-sm btn-info small-font py-2 px-4'>
                Submit
            </Button>
        </div>
        </> 
        
        }
  
    {/* {dbOperationStatus && <>
    <div className='d-flex flex-column justify-content-center align-items-center px-2 mr-2 shadow-sm p-3 mb-5 bg-white rounded table-responsive'>
        Successfully Submitted
    </div>
    </>}

    {!dbOperationStatus && <>
    <div className='d-flex flex-column justify-content-center align-items-center px-2 mr-2 shadow-sm p-3 mb-5 bg-white rounded table-responsive'>
        Failed to Submitted
    </div>
    </>} */}
      
    </div>
    </div>

  )
}

export default ManageBookingRate