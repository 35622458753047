import React, { useState, useEffect } from "react";
import GenericTable from "../../components/common/GenericTable";
import DynamicForm from "../../components/common/DynamicForm";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { GetAllData } from "../../api/ApiDataServiceCategory";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading";

const PostcodeDistrictPage = () => {
    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const addItemApiUrl = ApiURL.CreatePostcodeDistrict; //TODO: create api and sp
    const editItemApiUrl = ApiURL.EditPostcodeDistrict; //TODO: create api and sp
    const deleteItemApiUrl = ApiURL.DeletePostcodeDistrict; //TODO: create api and sp
    const getAllItemApiUrl = ApiURL.GetAllPostcodeDistricts; //TODO: create api and sp

    // Table columns
    const columns = [
        { key: "PostCodeId", alias: "#", isPrimaryKey: true, isVisible: false },         
        { key: "PostCode", alias: "Post Code", colWidth: '160px' },       
        { key: "LocationName", alias: "Location", colWidth: '260px' },
      ];
    
    // Ignore columns from search text
    const ignoredColumns = ["PostCodeId"];

    // Blank object for add item
    const addingObject = {
        PostCodeId: "", //PK
        PostCode: "",         
        LocationName: ""        
    };

    // Data model for add/edit item
    const formDataModel = [
        {
            name: "PostCodeId",
            label: "Id",
            type: "text",
            required: false,
            isPrimaryKey: true,
            isReadonly: true,
            isHidden: true,
            showLabel: true,
        },
        {
            name: "PostCode",
            label: "Post Code",
            type: "text",
            required: true,
            showLabel: true,
          },
        {
            name: "LocationName",
            label: "Location",
            type: "text",
            required: false,
            showLabel: true,
        },
       
    ];

    const getAllPostCodeDistrictsData = () => {
        GetAllData(getAllItemApiUrl)
        .then((json) => {
            setServiceError(false);
            if (json.StatusCode === 200) {
            setInitialData(json.DataResult);
            } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
                position: "bottom-center",
            });
            } else {
            cogoToast.error("Request Failed! Please Try Again.", {
                position: "bottom-center",
            });
            }
        })
        .catch((error) => {
            setServiceError(true);
        })
        .finally(() => {
            setLoading(false);
        });
    };
        
    useEffect(() => {       
        setLoading(true);        
        getAllPostCodeDistrictsData();
    }, [serviceError]);

    return (
        <div className="text-dark">
            <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-info font-weight-lighter">
                Postcodes and Districts
            </h4>
            </div>
    
            {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
            {loading ? (
                <Loading />
            ) : serviceError ? (
            <ServiceUnavailable />
            ) : (
            <>
                {initialData && (
                <GenericTable
                    data={initialData}
                    columns={columns}
                    ignoredColumns={ignoredColumns}
                    enablePaging={true}
                    enableSorting={true}
                    enableSearching={true}
                    enableDeleting={true}
                    addingObject={addingObject}
                    addTitle="Add Terminal"
                    editTitle="Edit Terminal"
                    addItemApiUrl={addItemApiUrl}
                    editItemApiUrl={editItemApiUrl}
                    deleteItemApiUrl={deleteItemApiUrl}
                    getAllItemApiUrl={getAllItemApiUrl}
                    AddItemComp={DynamicForm}
                    EditItemComp={DynamicForm}
                    formDataModel={formDataModel}
                    noOfColumnsLayout={1}
                    pageSizeOptions={[10, 50, 100]}
                />
                )}
            </>
            )}
        </div>
    )
}

export default PostcodeDistrictPage