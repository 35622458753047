import React, { Component } from 'react';
import URL from '../../components/master/URL';

class URLPage extends Component {
    render() {
        return (
            <div className="text-dark">
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="text-info font-weight-lighter">URL Managing</h4>
                </div>
       
                <URL />     
            </div>
        );
    }
}

export default URLPage;