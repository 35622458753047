import React, { useState, useEffect } from "react";
import GenericTable from "../../components/common/GenericTable";
import DynamicForm from "../../components/common/DynamicForm";
import { GetAllDataByPostMethodBody } from "../../api/ApiDataServiceCategory";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading"; 

function TrackBookingPage() {
    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serviceError, setServiceError] = useState(false); 
    const editItemApiUrl = ApiURL.EditBooking; 
    const getAllItemApiUrl = ApiURL.GetAllBookings;
    const [currencySymbol, setCurrencySymbol] = useState("");
    const culture = "en-US";
    const currency = "USD";  

    useEffect(() => {   
      const currencyFormatter = new Intl.NumberFormat(culture, {
        style: "currency",
        currency,
      });
      const parts = currencyFormatter.formatToParts(1);
      const symbolPart = parts.find((part) => part.type === "currency");
      setCurrencySymbol(symbolPart?.value || "");
    }, [culture, currency]);
    

    // Table columns
    const columns = [
        { key: "BookingId", alias: "#", isPrimaryKey: true, isVisible: false },
        { key: "BookingNo", alias: "Booking No." },
        { key: "BookingDate", alias: "Booking Date" },
        { key: "JourneyDate", alias: "Journey Date" },
        { key: "JourneyTime", alias: "Journey Time", isVisible: false },
        { key: "CustUserId", alias: "CustUser Id", isVisible: false },
        { key: "PassengerFName", alias: "First Name" },  //together with last name
        { key: "PassengerLName", alias: "Last Name" },
        { key: "IsPickUp", alias: "Is Pickup", isVisible: false },
        { key: "ServiceFrom", alias: "Journey From", isVisible: true },
        { key: "ServiceTo", alias: "Journey To", isVisible: true },       
        { key: "EmailId", alias: "Email Id", isVisible: false },
        { key: "MobileNo", alias: "Mobile No", isVisible: false },
        { key: "PassengerAddress", alias: "Passenger Address", isVisible: false },
        { key: "PostCode", alias: "Post Code", isVisible: false },
        { key: "NoOfPassengers", alias: "Passengers", isVisible: true },
        { key: "NoOfCheckInBaggage", alias: "No. Of CheckInBaggage", isVisible: false },
        { key: "NoOfCabinBaggage", alias: "No. Of CabinBaggage", isVisible: false },
        { key: "NoteForDriver", alias: "Note For Driver", isVisible: false },
        { key: "FlightDetails", alias: "Flight Details", isVisible: false },
        { key: "MeetAndGreetService", alias: "Meet And GreetService", isVisible: false },
        { key: "BabySeatRequired", alias: "Baby SeatRequired", isVisible: false },
        { key: "IsReturn", alias: "Return ?", isVisible: false },
        { key: "PaymentGatewayTransacNo", alias: "Transac No.", isVisible: false },
        { key: "RefBookingNo", alias: "Booking Ref. No.", isVisible: false },
        { key: "BookingAmount", alias: "Booking Amt." },
        { key: "PaidAmount", alias: "Paid Amt.", isVisible: false },
        { key: "CarName", alias: "Car Name", isVisible: false },  
        { key: "BookingStatusId", alias: "Status Id", isVisible: false  },
        { key: "BookingStatus", alias: "Status" },     
    ];

    // Ignore columns from search text
    const ignoredColumns = ["NoteForDriver"];

    // Blank object for add item
    const addingObject = {
        BookingId: "", //PK
        BookingNo: "",
        PassengerFName: "",      
        PassengerLName: "",
        BookingStatus: "",         
        // IsApproved: "",
    };

    const dataFilter = [ 
        { value: 0, label: 'All' },
        { value: 1, label: 'Booked' },
        { value: 2, label: 'In Progress' },
        { value: 3, label: 'Completed' },
        { value: 4, label: 'Cancelled' }        
      ]

    // Data model for add/edit item
    const formDataModel = [
        { name: "BookingId", label: "BookingId", type: "text", isHidden: true, },
        { name: "BookingNo", label: "Booking No", type: "label", required: true, showLabel: true },
        { name: "PassengerFName", label: "First Name", type: "label", required: true, showLabel: true },
        { name: "PassengerLName", label: "Last Name", type: "label", required: false, showLabel: true }, 
        { name: "EmailId", label: "Email Id",  type: "label", required: false, showLabel: true },
        // { name: "MobileNo", label: "Mobile No.",  type: "label", required: true, showLabel: true },
        { name: "BookingDate", label: "Booking Date", type: "label", required: true, showLabel: true },
        { name: "JourneyDate", label: "Journey Date", type: "label", required: true, showLabel: true },       
        {   
          name: "IsPickUp",
          label: "Is Pick Up?",
          type: "single-select",          
          title: "Select Pick Up", 
          optionKey: "IsPickUp",
          options: [
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },                                       
                  ],
          required: false,
          showLabel: true,
        },
        { name: "ServiceFrom", label: "Journey From", type: "label", required: false, showLabel: true, excludeDbOperation: true }, 
        { name: "ServiceTo", label: "Journey To", type: "label", required: false, showLabel: true, excludeDbOperation: true }, 
        { name: "FlightDetails", label: "Flight Details", type: "label", required: false, showLabel: true },
        {   
          name: "IsReturn",
          label: "Is Return?",
          type: "single-select",          
          title: "Select is Return", 
          optionKey: "IsReturn",
          options: [
                    { value: false, label: 'No' }, 
                    { value: true, label: 'Yes' },                                      
                  ],
          required: false,
          showLabel: true,
        }, 
        { name: "NoOfPassengers", label: "No. of Passengers", type: "number", required: true, showLabel: true, min:"1", max:"10" }, 
        { name: "NoOfCheckInBaggage", label: "No. of Check-in-Baggages", type: "number", required: true, showLabel: true, min:"0", max:"10" }, 
        { name: "NoOfCabinBaggage", label: "No. of Cabin-Baggages", type: "number", required: true, showLabel: true, min:"0", max:"10" }, 
        { name: "NoteForDriver", label: "Note for Driver", type: "label", required: false, showLabel: true },
        {   
          name: "MeetAndGreetService",
          label: "Meet & Greet Service?",
          type: "single-select",          
          title: "Select Meet & Greet Service", 
          optionKey: "MeetAndGreetService",
          options: [
                    { value: 0, label: 'No' }, 
                    { value: 1, label: 'Yes' },                    
                  ],
          required: false,
          showLabel: true,
        }, 
        {   
          name: "BabySeatRequired",
          label: "Baby Seat Required?",
          type: "single-select",          
          title: "Select Meet & Greet Service", 
          optionKey: "BabySeatRequired",
          options: [
                    { value: 0, label: 'No' }, 
                    { value: 1, label: 'Yes' },                    
                  ],
          required: false,
          showLabel: true,
        },
        { name: "PaymentGatewayTransacNo", label: "Transac No.", type: "label", required: true, showLabel: true },
        // { name: "RefBookingNo", label: "Ref Booking No.", type: "label", required: false, showLabel: true }, 
        { name: "FlightDetails", label: "Return Flight Details", type: "label", required: true, showLabel: true },
        { name: "BookingAmount", label: `Booking Amount (${currencySymbol})`, type: "number", required: false, showLabel: true, isReadonly:true  }, 
        { name: "PaidAmount", label: `Paid Amount (${currencySymbol})`, type: "number", required: false, showLabel: true, isReadonly:true },  
        { name: "CarName", label: "Car Name", type: "label", required: false, showLabel: true, excludeDbOperation: true },  
        {   
          name: "BookingStatusId",
          label: "Status",
          type: "single-select",          
          title: "Select Status", 
          optionKey: "BookingStatus",
          options: [
            { value: 1, label: 'Booked' },
            { value: 2, label: 'In Progress' },
            { value: 3, label: 'Completed' },
            { value: 4, label: 'Cancelled' }
          ],
          required: true,
          showLabel: true,
        }, 
    ];

    const getAllTrackBookingsData = () => {
        let reqData = {
            CustomerUserId: '',
            BookingStatusId: "0", //All
            StartDate: '',
            EndDate: ''
        };
    
        GetAllDataByPostMethodBody(getAllItemApiUrl, reqData)
          .then((json) => {
            setServiceError(false);
            if (json.StatusCode === 200) {
              setInitialData(json.DataResult);
            } else if (json.StatusCode === 500) {             
              cogoToast.error("Internal Server Error ! Please Try Again.", {
                position: "bottom-center",
              });
            } else {              
              cogoToast.error("Request Failed! Please Try Again.", {
                position: "bottom-center",
              });
            }
          })
          .catch((error) => {           
            setServiceError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      };
    
    useEffect(() => {
        setLoading(true);
        getAllTrackBookingsData();
    }, [serviceError]);

    return ( 
        <div className="text-dark">
          {/* Modal form for adding item */}
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-info font-weight-lighter">Track Bookings</h4>
          </div>
    
          {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
          {loading ? (
              <Loading />
          ) : serviceError ? (
            <ServiceUnavailable />
          ) : (
            <> 
              {initialData && (
                <GenericTable
                    data={initialData}
                    columns={columns}
                    ignoredColumns={ignoredColumns}
                    enablePaging={true}
                    enableSorting={true}
                    enableSearching={true}
                    enableDeleting={false}
                    //addingObject={addingObject}
                    //addTitle="Add Track Booking"
                    editTitle="Edit Track Booking"
                    //addItemApiUrl={addItemApiUrl}
                    editItemApiUrl={editItemApiUrl}
                    //deleteItemApiUrl={deleteItemApiUrl}
                    getAllItemApiUrl={getAllItemApiUrl}
                    isGetItemsByPost={true}
                    //AddItemComp={DynamicForm}
                    EditItemComp={DynamicForm}
                    formDataModel={formDataModel}
                    noOfColumnsLayout={4}
                    pageSizeOptions={[10, 50, 100]}
                    dataFilter={dataFilter}
                    defaultFilterText = "Booked"
                    dataFilterColumn = "BookingStatus"
                    dataFilterControlType = "dropdown"
                />
                )}
            </>
          )}
        </div>
    );
}

export default TrackBookingPage