import React, { useState, useEffect, useRef } from "react"; 
import AcceptReject from "./AcceptReject";
import CustomDropdown from "./CustomDropdown";
import MultiSelectDropdown from "./MultiSelectDropdown";
import SingleSelectDropdown from "./SingleSelectDropdown";


const DynamicForm = ({
  formDataModel,
  formData,
  setFormData,
  maxRecordId,
  noOfColumnsLayout = 1,
  validateField,
  selectedRecord,
  hasAcceptReject = false,
  onAcceptReject,
}) => {
  const columnCount = `col-lg-${12 / noOfColumnsLayout}`;
  const [touchedFields, setTouchedFields] = useState({});

  let primaryKeyColumn = formDataModel?.find((column) => column.isPrimaryKey);
  let keyField = "id";
  if (primaryKeyColumn) {
    keyField = primaryKeyColumn.name;
  }

  let excludeDbOperationElements = formDataModel?.filter((field) => field.excludeDbOperation);
  let requiredElements = formDataModel?.filter((field) => field.required); 
  requiredElements = requiredElements.filter((field) => excludeDbOperationElements.filter(exf => exf.name === field.name));


  const handleFieldBlur = (fieldName) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [fieldName]: true,
    }));
  };

  const handelValidateFieldInChild = () => {
    if (requiredElements?.length > 0) {
      const requiredFields = requiredElements.map((column) => column.name);
      const invalidFields = requiredFields.filter((field) => !formData[field]);
      if (invalidFields.length > 0) {
        //Some required fields are not filled
        return validateField(false);
      }
      return validateField(true);
    }
    return validateField(true);
  };

  const isValidField = (field) => {
    //console.log('field:',field)
    if (field.required) {
      const value = formData[field.name];
      //console.log('field value:',value);
       
      if (value instanceof File && value.size > 0) {
        return true;
      } else if (field.type === "datetime") {
        // Check if the value is an empty string or undefined
        return value !== undefined && value !== "";
      } else {
        // For other types, check if the value is an empty string
        return value !== undefined && value !== "";
      }
    }

    return true;
  };

  const handleImageChange = (fieldName, file) => {
    if (file && file.type.startsWith("image/")) {
      //const imageUrl = URL.createObjectURL(file);
      //   const _fileData = new FormData();
      //   _fileData.append("ItemImage", file);
      //   console.log(file);

      setFormData((prevData) => ({
        ...prevData,
        //fileData: _fileData, // Store the FormData object separately
        [fieldName]: file, // Store the file separately
      }));
    } else {
      // File is not an image, you can handle this case (e.g., show an error message)
      alert("Invalid file type. Please select an image.");
    }
  };

  const handleSelectedValue = (
    optionValue,
    optionText,
    fieldName,
    optionKey
  ) => {
    // console.log('fieldName1:',fieldName);
    // console.log('optionKey1:',optionKey);

    setFormData((prevData) => ({
      ...prevData,
      [optionKey]: optionText,
      [fieldName]: optionValue,
    }));
  };

  const handleSelectedValueSingle = (
    optionValue,
    optionText,
    fieldName,
    optionKey
  ) => {
    //  console.log('optionText:',optionText); //'Domestic Cooking'
    //  console.log('optionValue:',optionValue); // 6

    // console.log('fieldName2 field:',fieldName);
    // console.log('optionKey2 field:',optionKey);

    // console.log("fieldName2 value:", optionValue);
    // console.log("optionKey2 value:", optionText);
    
    if(optionKey === fieldName){
      setFormData((prevData) => ({
        ...prevData,       
        [fieldName]: optionValue,
        [optionKey]: optionValue,
      }));
    }
    else{
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: optionValue,
        [optionKey]: optionText,
      }));
    }
    
  };

  const handleChange = (fieldName, value) => {
    if (
      value &&
      typeof value === "object" &&
      value.type &&
      value.type.startsWith("image/")
    ) {
      handleImageChange(fieldName, value);
    } else if (value && fieldName === "datetime") {
      // Format the date-time value before setting it in the state
      const formattedDateTime = new Date(value).toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: formattedDateTime,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }

    //if add data, set PK as max record id
    if (!selectedRecord) {
      setFormData((prevData) => ({
        ...prevData,
        [keyField]: maxRecordId,
      }));
    }
  };

  const handelGetSelectedItems = (fieldName, fieldId, items) => {
    // console.log('selected fieldName:', fieldName)
    // console.log('selected fieldId:', fieldId)
    // console.log('selected items:', items)
    const selectedValues = items.map((item) => item.value);
    const selectedLabels = items.map((item) => item.label);
    // console.log('selectedValues:', selectedValues)
    // console.log('selectedLabels:', selectedLabels)
    setFormData((prevData) => ({
      ...prevData,
      [fieldId]: selectedValues,
      [fieldName]: selectedLabels,
    }));
  };

  const renderFormElement = (field, isTouched, onBlur) => {
    const commonProps = {
      id: field.name,
      name: field.name,
      value: field.name in formData ? field.type === "number" && (parseInt(formData[field.name]) < field.min || parseInt(formData[field.name]) > field.max)  ? "0": formData[field.name] : "",
      placeholder: field.required
        ? field.placeholder
          ? field.placeholder + " *"
          : field.label + " *"
        : field.placeholder
        ? field.placeholder
        : field.label,
      required: field.required,
      readOnly: field.isReadonly || false,
      className: `form-control form-control-sm border-2 ${isValidField(field) ? "" : "is-invalid"} ${field.type === "label" ? "my-0" : "my-0 mb-2"}`,
      onChange: (e) =>
        handleChange(
          field.name,
          field.type === "checkbox"
            ? e.target.checked
            : field.type === "image"
            ? e.target.files[0]
            : field.type === "multi-select"
            ? e
            : field.type === "single-select"
            ? e
            : e.target.value
        ),
      onBlur: onBlur,
      style: {
        border: '1px solid #999',
        backgroundColor: field.isReadonly? '#ECEBDE':''
      }
    };

    switch (field.type) {
      case "label":
        return (
          <>
            <input type={field.type} {...commonProps} readOnly={true} style={{border: '1px solid', backgroundColor:'#ECEBDE'}} />             
          </>
        );
      case "text":
      case "email":
        return (
          <>
            <input type={field.type} hidden={false} {...commonProps} />
            {isTouched && !isValidField(field) && (
              <div className="invalid-feedback">This field is required.</div>
            )}
          </>
        );
      case "number":
        return (
          <>
            <input type={field.type} hidden={false} {...commonProps} min={field.min} max={field.max} />
            {isTouched && !isValidField(field) && (
              <div className="invalid-feedback">This field is required.</div>
            )}
          </>
        );
      case "textarea":
        return (
          <>
            <textarea
              {...commonProps}
              rows={field.rows || 3} // Set the number of rows as needed
            />
            {isValidField(field) ? null : (
              <div className="invalid-feedback">This field is required.</div>
            )}
          </>
        );
      case "select":
        return (
          <>
            <CustomDropdown
              {...commonProps}
              options={field.options}
              title={field.title || "Select Item"}
              fieldName={field.name}
              optionKey={field.optionKey}
              getSelectedValue={handleSelectedValue}
              defaultRecord={selectedRecord && selectedRecord[field.name]}
            />
            {isTouched && !isValidField(field) && (
              <div className="invalid-feedback">
                Please select a valid option.
              </div>
            )}
          </>
        );
      case "single-select":
        return (
          <div>
            <SingleSelectDropdown
              {...commonProps}
              key={field.name}
              options={field.options}
              fieldName={field.name}
              optionKey={field.optionKey}
              getSelectedValue={handleSelectedValueSingle}
              defaultRecord={selectedRecord && selectedRecord[field.name]}
            />
            {isTouched && !isValidField(field) && (
              <div className="invalid-feedback">This field is required.</div>
            )}

            {/* {isValidField(field) ? null : (
              <div className="invalid-feedback">This field is required.</div>
            )} */}
          </div>
        );
      case "multi-select":
        return (
          <>
            <MultiSelectDropdown
              key={field.name}
              options={field.options}
              fieldName={field.name}
              fieldId={field.fieldId}
              getSelectedItems={handelGetSelectedItems}
            />
            {isValidField(field) ? null : (
              <div className="invalid-feedback">This field is required.</div>
            )}
          </>
        );
      case "checkbox":
        return (
          <div className="input-group">
            <label for={field.name} className="mt-3">
              {field.label}
            </label>
            <input
              type={field.type}
              checked={formData[field.name] || false}
              {...commonProps}
              style={{ width: 20, height: 26, marginTop: 26 }}
              className="mt-3 ml-4"
            />
          </div>
        );
      case "image":
        return (
          <>
            <div className="input-group mt-2">
              <input
                type="file"
                accept="image/*"
                id={field.name}
                name={field.name}
                onChange={(e) => handleChange(field.name, e.target.files[0])}
                required={field.required}
              />
            </div>
            {formData.ItemImage && (
              <div className="justify-content-center mt-0">
                <img
                  src={
                    formData.ItemImage instanceof File
                      ? URL.createObjectURL(formData.ItemImage)
                      : `${formData.ItemImage}`
                      //: `data:image/*;base64,${formData.ItemImage}`
                  }
                  alt={`${formData.ItemImage}`}
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </div>
            )}
            {isTouched && !isValidField(field) && (
              <div className="invalid-feedback">This field is required.</div>
            )}
          </>
        );
      case "image-hlink":
        return (
          <>
            <div className="input-group mt-2">
              <input
                type="file"
                accept="image/*"
                id={field.name}
                name={field.name}
                onChange={(e) => handleChange(field.name, e.target.files[0])}
                required={field.required}
              />
            </div>
            {formData.ItemImage && (
              <div className="justify-content-center mt-0">
                <img
                  src={
                    formData.ItemImage instanceof File
                      ? URL.createObjectURL(formData.ItemImage)
                      : `${formData.ItemImage}`
                  }
                  alt={`${formData.ItemImage}`}
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </div>
            )}
            {isTouched && !isValidField(field) && (
              <div className="invalid-feedback">This field is required.</div>
            )}
          </>
        );
      case "datetime":
        return (
          <>
            <input type="datetime-local" {...commonProps} />
            {isTouched && !isValidField(field) && (
              <div className="invalid-feedback">This field is required.</div>
            )}
          </>
        );
      // Add more cases for other form element types
      default:
        return null;
    }
  };

  useEffect(() => {
    handelValidateFieldInChild();
  }, [handleChange, handelValidateFieldInChild]);

  //on edit, set the formData as selectedRecord initially
  useEffect(() => {
    // Update the form fields when selectedRecord changes
    //alert('selectedRecord:', selectedRecord)
    if (selectedRecord) {
      //console.log("selectedRecord:", selectedRecord);
      setFormData(selectedRecord);
    }
    console.log('Edited form data:', formData);
  }, [selectedRecord, setFormData]);

  return (
    <>
     {/* Accept Reject form */}
      {hasAcceptReject && (
        <>
          <AcceptReject
            selectedRecord={selectedRecord}
            editedData={formData}
            setEditedData={setFormData}
            onAcceptReject={onAcceptReject}
          />
        </>
      )}

      {formDataModel && (
        <form id="fromWithAllKindsOfModel">
          <div className="row">
            {formDataModel.map((field) => (
              <div
                key={field.name}
                className={columnCount}
                hidden={field.isHidden || false}
              >
                {field.showLabel && (
                  <label
                    htmlFor={field.name}
                    className={`${
                      field.type === "single-select"
                        ? "mb-2"
                        : field.type === "image"
                        ? "mb-0"
                        : ""
                    }`}
                  >
                    {field.label} {field.required ? "*" : ""}
                  </label>
                )}
                {renderFormElement(field, touchedFields[field.name], () =>
                  handleFieldBlur(field.name)
                )}
              </div>
            ))}
          </div>
        </form>
      )}
     
    </>
  );
};

export default DynamicForm;
