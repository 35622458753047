import React, { useState, useEffect } from "react";
import GenericTable from "../../components/common/GenericTable";
import DynamicForm from "../../components/common/DynamicForm";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { GetAllData } from "../../api/ApiDataServiceCategory";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading";

const ManageServiceCategoryPage = () => {
  const [initialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const addItemApiUrl = ApiURL.CreateServiceCategory;
  const editItemApiUrl = ApiURL.EditServiceCategory;
  const deleteItemApiUrl = ApiURL.DeleteServiceCategory;
  const getAllItemApiUrl = ApiURL.GetAllServiceCategories;

  // Table columns
  const columns = [
    {
      key: "ServiceMasterId",
      alias: "#",
      isPrimaryKey: true,
      isVisible: false,
    },
    { key: "ItemImage", alias: "Image", type: "image-hlink", docpath:"ItemImagePath", height:"54px", width:"54px", colWidth: '30px' },
    { key: "ServiceMasterName", alias: "Category", colWidth: '400px' },    
    // { key: "UpdatedDate", alias: "Updated On" },
  ];

  // Ignore columns from search text
  const ignoredColumns = ["ServiceMasterId"];

  // Blank object for add item
  const addingObject = {
    ServiceMasterId: "", //PK
    ServiceMasterName: "",
    ItemImage: "",
  };

  // Data model for add/edit item
  const formDataModel = [
    {
      name: "ServiceMasterId",
      label: "Id",
      type: "text",
      required: false,
      isPrimaryKey: true,
      isReadonly: true,
      isHidden: true,
      showLabel: true,
    },
    {
      name: "ServiceMasterName",
      label: "Category",
      type: "text",
      required: true,
      showLabel: true,
    },
    {
      name: "ItemImage",
      label: "Image",
      type: "image",
      required: true,
      isReadonly: true,
      isHidden: false,
      showLabel: true,
    },
    // {
    //   name: "UpdatedDate",
    //   label: "Updated On",
    //   type: "datetime",
    //   required: false,
    //   isHidden: false,
    //   showLabel: true,
    // },
    // { name: 'gender', label: 'Gender', type: 'select', options: ['Male', 'Female', 'Other'], required: true },
    // { name: 'subscribe', label: 'Subscribe', type: 'checkbox', required: false },
    // { name: 'profileImage', label: 'Profile Image', type: 'image', required: true },
    // { name: 'birthdate', label: 'Birthdate', type: 'datetime', required: true },
  ];

  const getAllServiceCategoriesData = () => {
    GetAllData(getAllItemApiUrl)
      .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
          setInitialData(json.DataResult);
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        setServiceError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllServiceCategoriesData();
  }, [serviceError]);

  return (   
        <div className="text-dark">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-info font-weight-lighter">
              Service Categories
            </h4>
          </div>

          {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
          {loading ? (
              <Loading />
          ) : serviceError ? (
            <ServiceUnavailable />
          ) : (
            <>
              {initialData && (
                <GenericTable
                  data={initialData}
                  columns={columns}
                  ignoredColumns={ignoredColumns}
                  enablePaging={true}
                  enableSorting={true}
                  enableSearching={true}
                  enableDeleting={true}
                  addingObject={addingObject}
                  addTitle="Add Category"
                  editTitle="Edit Category"
                  addItemApiUrl={addItemApiUrl}
                  editItemApiUrl={editItemApiUrl}
                  deleteItemApiUrl={deleteItemApiUrl}
                  getAllItemApiUrl={getAllItemApiUrl}
                  AddItemComp={DynamicForm}
                  EditItemComp={DynamicForm}
                  formDataModel={formDataModel}
                  noOfColumnsLayout={1}
                  pageSizeOptions={[10, 50, 100]}
                />
              )}
            </>
          )}
        </div>            
    );
};

export default ManageServiceCategoryPage;
