import React, { Component } from 'react';
import PageTitle from "../components/home/PageTitle";

class AboutPage extends Component {
    render() {
        return (
            <>
               <div className="text-dark">                    
                    <PageTitle Title="About Us" />                   
                </div>
            </>
        );
    }
}

export default AboutPage;