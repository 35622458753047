import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PagingComponent from "./Pagination";
import SearchComponent from "./SearchComponent";
import ConfirmationModal from "./ConfirmationModal";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import cogoToast from "cogo-toast";
import "../../assets/css/CustomTable.css";
import "../../assets/css/AcceptReject.css";
import "../../assets/css/ConfirmationModal.css";
import "../../assets/css/ButtonStyle.css"; 
import { GetAllDataByPostMethodBody } from "../../api/ApiDataServiceCategory";
import SingleSelectDropdown from "./SingleSelectDropdown";


const GenericTable = ({
  data: initialData,
  columns,
  ignoredColumns,
  enablePaging,
  enableSorting,
  enableSearching,
  enableDeleting = false,
  addingObject,
  addTitle,
  editTitle,
  AddItemComp,
  EditItemComp,
  hasAcceptReject = false,
  formDataModel,
  noOfColumnsLayout = 1,
  pageSizeOptions = [10, 50, 100],
  addItemApiUrl,
  editItemApiUrl,
  deleteItemApiUrl,
  getAllItemApiUrl,
  isGetItemsByPost = false,
  filterParameters = null,
  dataFilter = [],
  defaultFilterText = null,
  dataFilterColumn = null,
  dataFilterControlType = null
}) => {
  const bigPageSize = 999999;
  const [ignoreColumns, setIgnoreColumns] = useState(ignoredColumns || []);
  const [data, setData] = useState(initialData);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]); //default page size is the first item
  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [addingData, setAddingData] = useState(addingObject);
  const [recordsFound, setRecordsFound] = useState(0);
  const [maxRecordId, setMaxRecordId] = useState(0);
  const [isAcceptedFilter, setIsAcceptedFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeFilterItem, setActiveFilterItem] = useState(null);
  const [activeFilter, setActiveFilter] = useState(defaultFilterText || (dataFilter && dataFilter[0]?.label) || null);

  //#region Get Data
  const getAllData = () => {
    setTimeout(() => {
      fetch(getAllItemApiUrl)
        .then((response) => response.json())
        .then((json) => {
          console.log("DataResult:", json.DataResult);
          if (json.StatusCode === 200) {
            if (getAllItemApiUrl.indexOf("allservices") > -1) {
              setData(json.DataResult.Records);
              filterParameters.pageSize =
                json.DataResult.FilterParameters.PageSize;
              filterParameters.pageNumber =
                json.DataResult.FilterParameters.PageNumber;
              filterParameters.searchText =
                json.DataResult.FilterParameters.SearchText;
              filterParameters.totalRecords =
                json.DataResult.FilterParameters.TotalRecords;
            } else {
              setData(json.DataResult);
            }
          } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
              position: "bottom-center",
            });
          } else {
            cogoToast.error("Request Failed! Please Try Again.", {
              position: "bottom-center",
            });
          }
        })
        .catch((error) => {
          cogoToast.error("Exception! Please Try Again.", {
            position: "bottom-center",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
  };

  const getAllDataWithPostBody = () => {
    let reqData = {
      searchText: "",
    };

    GetAllDataByPostMethodBody(getAllItemApiUrl, reqData)
      .then((json) => {
        if (json.StatusCode === 200) {           
          setData(json.DataResult);
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Exception! Please Try Again.", {
          position: "bottom-center",
        });
      })
      .finally(() => {
        setLoading(false);
      });
   
  };

  //#endregion

  //#region Add
  const handleOpenAddModal = () => {
    setAddingData(addingObject);
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setAddingData({});
    setAddModalOpen(false);
  };

  const handleAddItem = (addData) => {  
    //database insert
    insertItemToDB(addData);
    setAddModalOpen(false);
  };

  const insertItemToDB = (addData) => {
    const formData = new FormData();
    formDataModel.map((item) => {
      if(item.excludeDbOperation){
        if(item.excludeDbOperation === false){
          formData.append([item.name], addData[item.name]);
        }
      }
      else{
        formData.append([item.name], addData[item.name]);
      }

      // formData.append([item.name], addData[item.name]);
    });

    fetch(addItemApiUrl, {
      method: "POST",
      headers: {
        // Don't set Content-Type, let the browser set it automatically for FormData
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.StatusCode === 200) {
          setData([...data, json.DataResult]);
          if(isGetItemsByPost) {
            getAllDataWithPostBody();
          }
          else {
            getAllData();
          }
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Exception! Please Try Again.", {
          position: "bottom-center",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //#endregion

  //#region Edit

  const openEditModal = (record) => {
    console.log('Editing record:',record);
    setSelectedRecord(record);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setSelectedRecord(null);
    setEditedData({});
  };

  const updateItemToDB = (primaryKeyColumns, editingData) => {
    //------------------------
    let key = primaryKeyColumns[0].key; //it should be dynamic, pks not always 0 item.
    let value = editingData[key];
    editItemApiUrl = editItemApiUrl.replace("{id}", value);
    //------------------------
    const formData = new FormData();
    formDataModel.map((item) => {
      if(item.excludeDbOperation){
        if(item.excludeDbOperation === false){
          formData.append([item.name], editingData[item.name]);
        }
      }
      else{
        formData.append([item.name], editingData[item.name]);
      }      
    });

    fetch(editItemApiUrl, {
      method: "POST",
      headers: {
        // Don't set Content-Type, let the browser set it automatically for FormData
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.StatusCode === 200) {
          updateSettlementAtUI(primaryKeyColumns, editingData);
          if(isGetItemsByPost) {
              getAllDataWithPostBody();
            }
          else {
              getAllData();
            }

        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Exception! Please Try Again.", {
          position: "bottom-center",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditItem = (editedRecord) => {
    const primaryKeyColumns = columns.filter((column) => column.isPrimaryKey);

    if (primaryKeyColumns.length > 0) {
      // Extract the primary key values from the edited record
      //const primaryKeyValues = primaryKeyColumns.map((column) => editedRecord[column.key]);

      // Update the original data array with the changes
      const updatedData = data.map((record) => {
        const isMatch = primaryKeyColumns.every(
          (column) => record[column.key] === editedRecord[column.key]
        );
        // return isMatch ? editedRecord : record;
        if (isMatch === true) {
          //DB operation
          updateItemToDB(primaryKeyColumns, editedRecord);
          return editedRecord;
        } else {
          return record;
        }
      });

      // Update the state with the modified data
      //setData(updatedData);
    }

    // Close the modal after saving changes
    closeEditModal();
  };

  const updateSettlementAtUI = (primaryKeyColumns, editedRecord) => {
    const updatedData = data.map((record) => {
      const isMatch = primaryKeyColumns.every(
        (column) => record[column.key] === editedRecord[column.key]
      );
      return isMatch ? editedRecord : record;
    });

    setData(updatedData);
  };

  //#endregion

  //#region Delete

  const openDeleteConfirmation = (record) => {
    setSelectedRecord(record);
    setDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setSelectedRecord(null);
    setDeleteConfirmation(false);
  };

  const deleteItemFromDB = (primaryKeyColumns) => {
    //------------------------
    let key = primaryKeyColumns[0].key; //it should be dynamic, pks not always 0 item.
    deleteItemApiUrl = deleteItemApiUrl.replace("{id}", selectedRecord[key]);

    //------------------------

    fetch(deleteItemApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedRecord[key]),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.StatusCode === 200) {
          deleteSettlementAtUI(primaryKeyColumns);
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Exception! Please Try Again.", {
          position: "bottom-center",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    const primaryKeyColumns = columns.filter((column) => column.isPrimaryKey);
    if (primaryKeyColumns.length > 0) {
      //DB operation
      deleteItemFromDB(primaryKeyColumns);
    }
    closeDeleteConfirmation();
  };

  const deleteSettlementAtUI = (primaryKeyColumns) => {
    const isMatch = (item) =>
      primaryKeyColumns.every(
        (column) => item[column.key] === selectedRecord[column.key]
      );
    const updatedData = data.filter((item) => !isMatch(item));
    setData(updatedData);
  };

  //#endregion

  //#region Paging

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(filteredData.length / pageSize)) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize((enablePaging && newPageSize) || bigPageSize);
    setCurrentPage(1);
  };

  const filteredData = useMemo(() => {
    // Apply search text filter

    let searchedData = data.filter((item) => {
      return columns
        .filter((column) => !ignoreColumns.includes(column.key))
        .some((column) => {
          const columnValue = String(item[column.key]);
          return columnValue
            .toLowerCase()
            .includes(searchText.toLowerCase().trim());
        });
    });

    if (hasAcceptReject) {
      searchedData = searchedData.filter((item) => {
        return item.IsApproved === isAcceptedFilter;
      });
    }

    //Filter if exists
    if(dataFilter && dataFilter.length > 0 && activeFilter){
      if(activeFilter.toLowerCase()!=='all'){
        searchedData = searchedData.filter(item=>{
          return columns
            .filter(column => !ignoreColumns.includes(column.key))
            .some(column =>{
              const colValue = String(item[dataFilterColumn]);         
              return colValue.toLowerCase() === activeFilter.toLowerCase().trim();
            });
        });
      }
    }
  
    // Apply sorting
    const sortedData = enableSorting
      ? searchedData.slice().sort((a, b) => {
          const aValue = String(a[sortColumn]);
          const bValue = String(b[sortColumn]);

          if (aValue < bValue) {
            return sortOrder === "asc" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortOrder === "asc" ? 1 : -1;
          }
          return 0;
        })
      : searchedData;

    // sortedData.length =
    //   (filterParameters && filterParameters.totalRecords) || sortedData.length;
    setRecordsFound(sortedData.length); // filterParameters && filterParameters.pageNumber
    setMaxRecordId(data.length + 1); //todo: delete this line of code. not required in real scenario
    return sortedData;
  }, [
    data,
    columns,
    ignoreColumns,
    searchText,
    enableSorting,
    sortColumn,
    sortOrder,
    maxRecordId,
    isAcceptedFilter,
    currentPage,
    activeFilter
  ]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex =
    (enablePaging && startIndex + pageSize) || startIndex + bigPageSize;
  const displayedData = filteredData.slice(startIndex, endIndex);

  //#endregion

  //#region Sorting

  const handleSort = (column) => {
    const newSortOrder =
      column === sortColumn && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  //#endregion

  const handleSelectedDataFilter = (filteredOption) =>{
      setActiveFilter(filteredOption.label);
      setActiveFilterItem(filteredOption);
  }

  const handleSelectedValueSingle = (
    optionValue,
    optionText,
    fieldName,
    optionKey
  ) => {   

    setActiveFilter(optionText);
    setActiveFilterItem({label: optionText, value:optionValue });

    // setFormData((prevData) => ({
    //   ...prevData,
    //   [fieldName]: optionValue,
    //   [optionKey]: optionText,
    // }));
  };


  //#region Searching

  const handleSearchChange = (text) => {
    // setSearchText(text);
    // setCurrentPage(1);
  };

  const handleSearchClick = (text) => {
    setSearchText(text);
    setCurrentPage(1);
  };

  //#endregion

  //#region Approve Reject

  const approveRejectItemToDB = (primaryKeyColumns, editedRecord) => {
    // //------------------------
    // let key = primaryKeyColumns[0].key; //it should be dynamic, pks not always 0 item.
    // let value = editingData[key];
    // editItemApiUrl = editItemApiUrl.replace("{id}", value);
    // //------------------------
    // const formData = new FormData();
    // formDataModel.map((item) => {
    //   formData.append([item.name], editingData[item.name]);
    // });

    fetch(editItemApiUrl, {
      method: "POST",
      headers: {
        // Don't set Content-Type, let the browser set it automatically for FormData
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //body: formData,
      body: JSON.stringify(editedRecord),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.StatusCode === 200) {
          //updateSettlementAtUI(primaryKeyColumns, editedRecord);
          if(isGetItemsByPost) {
            getAllDataWithPostBody();
          }
          else {
            getAllData();
          }
          
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Exception! Please Try Again.", {
          position: "bottom-center",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApproveReject = (editedRecord) => {
    const primaryKeyColumns = columns.filter((column) => column.isPrimaryKey);
    if (primaryKeyColumns.length > 0) {
      // Extract the primary key values from the edited record
      //const primaryKeyValues = primaryKeyColumns.map((column) => editedRecord[column.key]);

      // Update the original data array with the changes
      const updatedData = data.map((record) => {
        const isMatch = primaryKeyColumns.every(
          (column) => record[column.key] === editedRecord[column.key]
        );
        //return isMatch ? editedRecord : record;
        if (isMatch === true) {
          //DB operation
          approveRejectItemToDB(primaryKeyColumns, editedRecord);
          return editedRecord;
        } else {
          return record;
        }
      });

      // Update the state with the modified data
      setData(updatedData);
    }

    // Close the modal after saving changes
    closeEditModal();
  };

  const handleFilterOnAcceptReject = () => {
    setIsAcceptedFilter((prevItem) => !prevItem);
    setCurrentPage(1);
  };

  //#endregion

  return (
    <div>
      <div>
        <div className="row justify-content-between align-items-center mx-0">
          <div>
            {enableSearching && (
              <SearchComponent
                columns={columns}
                onSearchChange={handleSearchChange}
                onClick={handleSearchClick}
              />
            )}
          </div>
          <div>
            {dataFilter && dataFilter.length > 0 && (
              <div className="data-filter">
              {dataFilterControlType && dataFilterControlType !== "dropdown" && dataFilter.map((item, index) => 
                <span key={index} 
                className={`button 
                  ${activeFilter === item.label ? "active" : ""} 
                  ${dataFilter.length === 1
                  ? "rounded-left rounded-right"
                  : index === 0
                  ? "rounded-left"
                  : index === dataFilter.length - 1
                  ? "rounded-right"
                  : "" }`} 
                onClick={()=>handleSelectedDataFilter(item)}>{item.label}</span>
              )} 
              {dataFilterControlType && dataFilterControlType === "dropdown" && 
              <SingleSelectDropdown              
              key={'datafilter_'+ dataFilterColumn}
              options={dataFilter.map(item => item)}
              fieldName={dataFilterColumn}
              optionKey={dataFilterColumn}
              getSelectedValue={handleSelectedValueSingle}
              defaultRecord={activeFilter} />
              }
            </div>
           
            )}
            {hasAcceptReject && (
              <h6>
                <div
                  className={
                    isAcceptedFilter === true
                      ? "accept-yes-label"
                      : "font-weight-lighter"
                  }
                >
                  <Form.Group
                    controlId="formCheckbox"
                    className="mb-0 justify-content-center align-items-center"
                  >
                    <Form.Label className="mr-2">Approve status</Form.Label>
                    <Form.Check
                      type="switch"
                      label={isAcceptedFilter === true ? "Yes" : "No"}
                      name="IsActive"
                      value={isAcceptedFilter}
                      onChange={handleFilterOnAcceptReject}
                      title="Yes or No"
                      inline
                    />
                  </Form.Group>
                </div>
              </h6>
            )}
            {AddItemComp && (
              <div>
                {/* Add New Item */}
                <Button
                  variant="primary"
                  onClick={handleOpenAddModal}
                  backdrop="static"
                  // keyboard={false}
                  className="my-2 btn-sm btn-info btn-small"                  
                >
                  <i className="fa fa-add" aria-hidden="true"></i> Add New
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* thead-dark */}
      {/* Render table with displayedData */}
      <div className="small-font" style={{overflow:'auto'}}>
        <table className="table table-hover table-striped">
          {/* Table header */}
          <thead className="font-weight-lighter small-font">
            <tr className="small-font">
              <th scope="col" className="text-center small-font w-[120]" key={"th_action"}>
                <div className="mx-1 form-control-sm small-font">Action</div>
              </th>
              {columns.map((column) =>
                column.isVisible === false ? null : column.type === "image" ||
                  column.type === "image-hlink" ? (
                  <th
                    key={column.key}
                    scope="col"
                    className={enableSorting ? "cursor-pointer small-font" : "small-font"}                     
                    style={{width:`${column.colWidth? column.colWidth:'100px'}`}}
                    onClick={() => enableSorting && handleSort(column.key)}
                  >
                    <div className="row d-flex justify-content-between gap-3 small-font">
                      <div className="mx-2 form-control-sm small-font">{column.alias}</div>
                      {sortColumn && sortColumn === column.key && (
                        // <span>{sortDirection === 'asc' ? <img src='/Images/arrows/up-arrows.png' alt='up' width={20} height={20} className='mx-2 pull-right' /> : <img src='/Images/arrows/down-arrows.png' alt='down' width={20} height={20} className='mx-2 pull-right' />}</span>
                        <div className="text-muted mx-2 form-control-sm small-font">
                          {sortOrder === "asc" ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                ) : (
                  <th
                    key={column.key}
                    scope="col"
                    className={`small-font ${enableSorting} ? "cursor-pointer" : ""`}
                    onClick={() => enableSorting && handleSort(column.key)}
                    style={{width:`${column.colWidth? column.colWidth:'100px'}`}}
                  >
                    <div className="row d-flex justify-content-between gap-3 small-font">
                      <div className="mx-2 form-control-sm small-font">{column.alias}</div>
                      {sortColumn && sortColumn === column.key && (
                        // <span>{sortDirection === 'asc' ? <img src='/Images/arrows/up-arrows.png' alt='up' width={20} height={20} className='mx-2 pull-right' /> : <img src='/Images/arrows/down-arrows.png' alt='down' width={20} height={20} className='mx-2 pull-right' />}</span>
                        <div className="text-white mx-2 form-control-sm small-font">
                          {sortOrder === "asc" ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                )
              )}
              {/* <th scope="col" className="text-center small-font" key={"th_action"}>
                <div className="mx-2 form-control-sm small-font">Action</div>
              </th> */}
            </tr>
          </thead>

          {/* Table body */}
          <tbody>
            {displayedData.map((item, index) => (
              <tr key={index} className="text-left">
                <td className="text-center align-middle col-md-1" key={"action_" + index}>
                  <div className="gap-1">
                  {enableDeleting && (
                      <button
                        key={"delete_" + index}
                        className="btn btn-danger btn-sm mx-1"
                        onClick={() => openDeleteConfirmation(item)}  
                        title="Delete"                     
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>                        
                      </button>
                    )}
                    {EditItemComp && (
                      <button
                        key={"edit_" + index}
                        className="btn btn-info btn-sm mx-1"
                        onClick={() => openEditModal(item)}      
                        title="Edit" 
                      >
                        <i className="fa fa-edit" aria-hidden="true"></i>                          
                      </button>
                    )}

                    
                  </div>
                </td>
                {columns.map((column, index2) =>
                  column.isVisible === false ? null : (
                    <>
                      <td key={column.key} className={`${column.alignCenter? 'text-center': 'text-left'} ${column.type === "image" || column.type === "image-hlink"? "text-center align-middle":"align-middle"}`}>
                        {column.type === "image" ? (
                          <img
                            src={
                              item[column.key] instanceof File
                                ? URL.createObjectURL(item[column.key])
                                : `data:image/*;base64,${item[column.key]===""? "No Image":item[column.key]}`
                            }
                            alt={`${item[column.key]}`}
                            referrerPolicy="no-referrer"
                            style={{maxHeight:`${column.height? column.height:'100px'}`,maxWidth:`${column.width? column.width: '100px'}` }}
                          />
                        ) : column.type === "image-hlink" ? (
                          <img                          
                            src={
                              item[column.key] instanceof File
                                ? URL.createObjectURL(item[column.key]) // Handle File objects
                                : item[column.key].startsWith('data:image') // Check if it's a base64 string
                                ? item[column.key]
                                : item[column.key] // Use the URL directly
                            }
                            alt={`${item[column.key]}`}
                            referrerPolicy="no-referrer"
                            style={{maxHeight:`${column.height? column.height:'100px'}`,maxWidth:`${column.width? column.width: '100px'}` }}
                          />
                        ) : column.type === "datetime" ? (
                          new Date(item[column.key]).toLocaleString("en-US", {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        ) : column.render ? (
                          column.render(item[column.key])
                        ) : column.isHLink === true ? (
                          <a
                            href={item[column.link]}
                            download={`${item[column.key]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >                            
                            {item[column.key]} 
                          </a>
                        ) : (
                          item[column.key]
                        )}
                      </td>
                    </>
                  )
                )}

                {/* <td className="text-center col-md-2 align-middle" key={"action_" + index}>
                  <div className="btn-group" role="group">
                    {EditItemComp && (
                      <button
                        key={"edit_" + index}
                        className="btn btn-primary btn-sm btn-small"
                        onClick={() => openEditModal(item)}                        
                      >
                        <i className="fa fa-edit" aria-hidden="true"></i> Edit
                      </button>
                    )}

                    {enableDeleting && (
                      <button
                        key={"delete_" + index}
                        className="btn btn-danger btn-sm btn-small"
                        onClick={() => openDeleteConfirmation(item)}                       
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                        Delete
                      </button>
                    )}
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Render the PagingComponent if enablePaging is true */}
      {enablePaging && (
        <PagingComponent
          currentPage={currentPage}
          totalPages={Math.ceil(filteredData.length / pageSize)}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          recordsFound={recordsFound}
          pageSizeOptions={pageSizeOptions}
        />
      )}

      {/* Render the AddModal */}
      {AddItemComp && (
        <AddModal
          title={addTitle || "Add Item"}
          isOpen={isAddModalOpen}
          closeModal={handleCloseAddModal}
          onSave={handleAddItem} 
        >
          {/* <AddItemComp addingData={addingData} setAddingData={setAddingData} maxRecordId={maxRecordId} /> */}
          <AddItemComp
            addingData={addingData}
            setAddingData={setAddingData}
            maxRecordId={maxRecordId}
            formDataModel={formDataModel}
            noOfColumnsLayout={noOfColumnsLayout}
            formData={addingData}
            setFormData={setAddingData}
          />
        </AddModal>
      )}

      {/* Render the EditModal */}
      {EditItemComp && (
        <EditModal
          title={editTitle || "Edit Item"}
          isOpen={isEditModalOpen}
          closeModal={closeEditModal}
          onSave={handleEditItem}
          hasAcceptReject={hasAcceptReject}
          onAcceptReject={handleApproveReject}
          acceptText={"Approve"}
          rejectText={"Reject"}        
        >
          {/* <EditItemComp selectedRecord={selectedRecord} editedData={editedData} setEditedData={setEditedData} /> */}
          <EditItemComp
            selectedRecord={selectedRecord}
            editedData={editedData}
            setEditedData={setEditedData}
            formDataModel={formDataModel}
            noOfColumnsLayout={noOfColumnsLayout}
            formData={editedData}
            setFormData={setEditedData}
          />
        </EditModal>
      )}

      {/* Delete confirmation */}
      <ConfirmationModal
        deleteConfirmation={deleteConfirmation}
        closeDeleteConfirmation={closeDeleteConfirmation}
        handleDelete={handleDelete}
      />
    </div>
  );
};

GenericTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  enablePaging: PropTypes.bool,
  enableSorting: PropTypes.bool,
  enableSearching: PropTypes.bool,
};

GenericTable.defaultProps = {
  enablePaging: false,
  enableSorting: false,
  enableSearching: false,
};

export default GenericTable;
