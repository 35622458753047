import React from 'react'
import Sidebar from '../common/Sidebar'
import Topbar from '../common/Topbar'
import PageTitle from '../home/PageTitle'
import Footer from '../common/Footer'

const Layout = ({onLogout, children}) => {
  return (
    <div id="wrapper">      
        <Sidebar />       
        <main id="content-wrapper" className="d-flex flex-column">          
            <div id="content">
                <Topbar onLogout={onLogout} />
                <div className="container-fluid">
                    <PageTitle Title="" />
                    {children}
                </div>
            </div> 
            <Footer />                      
        </main>
        
    </div>
  )
}

export default Layout