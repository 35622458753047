import React, { Component } from 'react';
import SearchLead from '../../components/transaction/SearchLead';

class SearchLeadPage extends Component {
    render() {
        return (
            <div className="text-dark">
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="text-info font-weight-lighter">Manage Search Lead</h4>
                </div>

                <SearchLead />
            </div>               
        );
    }
}

export default SearchLeadPage;