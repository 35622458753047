import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import LoginImage from "../../assets/images/login-1.webp";
import validation from "../../validation/validation";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import LocalStorageHelper from "../../LocalStorageHelper/LocalStorageHelper";
import PropTypes from "prop-types";
import _ from "lodash";

const AdminLogin = ({ onLogin, setUser, history }) => {
  const debounceTime = 1000; //in millisecond
  const [userLoginRequest, setUserLoginRequest] = useState({
    email: "",
    password: "",
  });

  const [loginSuccess, setLoginSuccess] = useState(false);

  const handleSignIn = () => {
    // _.debounce(() => {
      console.log(userLoginRequest)
    if (userLoginRequest.email.trim() === "") {
      setLoginSuccess(false);
      cogoToast.error("Please enter Email Id!", {
        position: "bottom-center",
      });
    } else if (
      userLoginRequest.email &&
      userLoginRequest.email.length > 0 &&
      !validation.EmailRegx.test(userLoginRequest.email)
    ) {
      setLoginSuccess(false);
      cogoToast.error("Invalid Email Id!", { position: "bottom-center" });
    } else if (userLoginRequest.password.trim() === "") {
      setLoginSuccess(false);
      cogoToast.error("Please enter valid password!", {
        position: "bottom-center",
      });
    } else {
      var param = {
        EmailId: userLoginRequest.email,
        Password: userLoginRequest.password,
      };

      
      
      fetch(ApiURL.AdminLoginApiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      })
        .then((data) => data.json())
        .then((response) => {
          let respData = response;
          //console.log("respData:", respData);
          if (respData.StatusCode === 200) {
            let dataResult = respData.DataResult;

            let stringifiedUser = JSON.stringify({
              AccessToken: dataResult.AccessToken,
              UserName: dataResult.UserName,
              Email: dataResult.EmailId,
              ProfilePic: dataResult.ProfilePic,
            });
            //console.log("stringifiedUser:", stringifiedUser);

            // Simulating a successful login
            localStorage.setItem("isAuthenticated", "true");
            localStorage.setItem("User", stringifiedUser);

            //LocalStorageHelper.setUser(stringifiedUser);
            setUser(stringifiedUser);
            setLoginSuccess(true);
            onLogin();
          } else if (respData.StatusCode === 400) {
            setLoginSuccess(false);
            cogoToast.error(respData.Message, { position: "bottom-center" });
          } else if (respData.StatusCode === 500) {
            setLoginSuccess(false);
            cogoToast.error("Internal Server Error ! Please Try Again.", {
              position: "bottom-center",
            });
          }
        })
        .catch((error) => {
          console.log("login error:", error);
          setLoginSuccess(false);
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        });
    }
    // }, debounceTime);
  };

  const handleChange = (fieldName, value) => {
    setUserLoginRequest((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const redirectToDashboardPage = () => {
    let token = LocalStorageHelper.getAccessToken();
    if (token != null || token != "") {
      history?.push("/");
    }
  };

  useEffect(() => {
    redirectToDashboardPage();

  }, [loginSuccess, setUser]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {      
      handleSignIn();
    }
  };

  return (
    <>
      <div className="container">
        {/* <!-- Outer Row --> */}
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image">
                    <img src={LoginImage} alt="..." width="500" />
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <form className="user">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-md"
                            name="email"
                            aria-describedby="emailHelp"
                            placeholder="john@xyz.com"
                            value={userLoginRequest.email}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-md"
                            name="password"
                            placeholder="**********"
                            autoComplete="off"
                            value={userLoginRequest.password}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                        {/* <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="customCheck"
                            />
                            <label className="custom-control-label">
                              Remember Me
                            </label>
                          </div>
                        </div> */}
                        <div className="mt-4">&nbsp;</div>
                        <div
                          className="btn btn-primary btn-block"
                          onClick={handleSignIn}
                        >
                          Login
                        </div>
                        <hr />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AdminLogin.propTypes = {
  setUser: PropTypes.func.isRequired,
};

export default AdminLogin;
