import React, { useState, useEffect } from "react";
import GenericTable from "../../components/common/GenericTable";
import DynamicForm from "../../components/common/DynamicForm";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { GetAllData } from "../../api/ApiDataServiceCategory";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading";

const CarTypePage = () => {
    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const addItemApiUrl = ApiURL.CreateCarType; //TODO: create api and sp
    const editItemApiUrl = ApiURL.EditCarType; //TODO: create api and sp
    const deleteItemApiUrl = ApiURL.DeleteCarType; //TODO: create api and sp
    const getAllItemApiUrl = ApiURL.GetCarTypes; //TODO: create api and sp

    // Table columns
    const columns = [
        { key: "CarId", alias: "#", isPrimaryKey: true, isVisible: false },   
        // { key: "CarImage", alias: "Car Image", colWidth: '120px'}, 
        { key: "ItemImage", alias: "Image", type: "image-hlink", height:"54px", width:"54px", colWidth: '80px' },
        { key: "CarName", alias: "Car Name", colWidth: '160px' },       
        { key: "CarDescription", alias: "Description", colWidth: '260px' },  
        { key: "NoPassenger", alias: "No. of Passenger", colWidth: '190px' },
        { key: "NoHandBaggage", alias: "No. of Hand Baggage", colWidth: '190px' },
        { key: "NoBigLuggage", alias: "No. of Big Luggage", colWidth: '190px' }        
      ];
    
    // Ignore columns from search text
    const ignoredColumns = ["CarId"];

    // Blank object for add item
    const addingObject = {
        CarId: "", //PK
        CarName: "",
        // CarImage: "",
        ItemImage: "",
        CarDescription: "",
        NoPassenger: "0",
        NoHandBaggage: "0",
        NoBigLuggage: "0"
    };

    // Data model for add/edit item
    const formDataModel = [
        {
            name: "CarId",
            label: "Id",
            type: "text",
            required: false,
            isPrimaryKey: true,
            isReadonly: true,
            isHidden: true,
            showLabel: true,
        },        
        {
            // name: "CarImage",
            name: "ItemImage",
            label: "Image",
            type: "image-hlink",
            required: true,
            isReadonly: true,
            isHidden: false,
            showLabel: true,
        },
        {
            name: "CarName",
            label: "Car Name",
            type: "text",
            required: true,
            showLabel: true,
          },
        {
            name: "CarDescription",
            label: "Description",
            type: "text",
            required: false,
            showLabel: true,
        },
        {
            name: "NoPassenger",
            label: "No. of Passenger",
            type: "number",
            required: false,
            showLabel: true,
        },
        {
            name: "NoHandBaggage",
            label: "No. of Hand Baggage",
            type: "number",
            required: false,
            showLabel: true,
        },
        {
            name: "NoBigLuggage",
            label: "No. of Big Luggage",
            type: "number",
            required: false,
            showLabel: true,
        },
        // {
        //     name: "IsActive",
        //     label: "Is Active",
        //     type: "checkbox",
        //     required: false,
        //     showLabel: false,
        // },
    ];

    const getAllCarTypesData = () => {
        GetAllData(getAllItemApiUrl)
        .then((json) => {
            setServiceError(false);
            if (json.StatusCode === 200) {
            setInitialData(json.DataResult);
            } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
                position: "bottom-center",
            });
            } else {
            cogoToast.error("Request Failed! Please Try Again.", {
                position: "bottom-center",
            });
            }
        })
        .catch((error) => {
            setServiceError(true);
        })
        .finally(() => {
            setLoading(false);
        });
    };
        
    useEffect(() => {
        setLoading(true);        
        getAllCarTypesData();
    }, [serviceError]);


    return (
        <div className="text-dark" >
            <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-info font-weight-lighter">
                Car Types
            </h4>
            </div>
    
            {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
            {loading ? (
                <Loading />
            ) : serviceError ? (
            <ServiceUnavailable />
            ) : (
            <>
                {initialData && (
                <GenericTable
                    data={initialData}
                    columns={columns}
                    ignoredColumns={ignoredColumns}
                    enablePaging={true}
                    enableSorting={true}
                    enableSearching={true}
                    enableDeleting={true}
                    addingObject={addingObject}
                    addTitle="Add Terminal"
                    editTitle="Edit Terminal"
                    addItemApiUrl={addItemApiUrl}
                    editItemApiUrl={editItemApiUrl}
                    deleteItemApiUrl={deleteItemApiUrl}
                    getAllItemApiUrl={getAllItemApiUrl}
                    AddItemComp={DynamicForm}
                    EditItemComp={DynamicForm}
                    formDataModel={formDataModel}
                    noOfColumnsLayout={1}
                    pageSizeOptions={[10, 50, 100]}
                />
                )}
            </>
            )}
        </div>
    )
}

export default CarTypePage