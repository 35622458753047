import React, { Fragment, useEffect, useState } from "react";
// import { Redirect, Route, Router, Switch } from "react-router";
import { Redirect } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import Layout from "../components/layout/Layout";
import PrivateRoute from "../components/common/PrivateRoute";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import URLPage from "../pages/Master/URLPage";
import TravelCircuitPage from "../pages/Master/TravelCircuitPage";
import SpecialistPage from "../pages/Master/SpecialistPage";
import SearchLeadPage from "../pages/Transaction/SearchLeadPage";
import ManageServiceCategoryPage from "../pages/Master/ManageServiceCategoryPage";
import ServicesPage from "../pages/Master/ServicesPage";
import ProvidersPage from "../pages/Master/ProvidersPage"; 
import AdminLogin from "../components/common/AdminLogin";
import useUser from "../components/common/useUser";
import AirportTerminalPage from "../pages/Master/AirportTerminalPage";
import CarOperatorPage from "../pages/Master/CarOperatorPage";
import CarTypePage from "../pages/Master/CarTypePage";
import PartnerPage from "../pages/Master/PartnerPage";
import PostcodeDistrictPage from "../pages/Master/PostcodeDistrictPage";
import TrackBookingPage from "../pages/Master/TrackBookingPage";
import ManageBookingRate from "../pages/Transaction/ManageBookingRate";

function AppRoute() {
  const location = useLocation();

  // List of paths where the Layout should not be applied
  const excludeLayoutPaths = ["/login"];
  const isLayoutExcluded = excludeLayoutPaths.includes(location.pathname);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { setUser, token } = useUser();

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  useEffect(() => {
    // Check if the user is authenticated in localStorage
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Fragment>
      {isLayoutExcluded ? (
        <Switch>
          <Route path="/login" exact> 
            {isAuthenticated ? (
              <Redirect to="/home" />
            ) : (
              <AdminLogin onLogin={handleLogin} setUser={setUser} />
            )}
          </Route>
        </Switch>
      ) : (
        <Layout onLogout={handleLogout}>
          <Switch>
            <PrivateRoute
              exact
              path="/home"
              component={() => <HomePage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/"
              component={() => <HomePage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/about"
              component={() => <AboutPage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/url"
              component={() => <URLPage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/tc"
              component={() => <TravelCircuitPage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/spl"
              component={() => <SpecialistPage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/sl"
              component={() => <SearchLeadPage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/service-category"
              component={() => (
                <ManageServiceCategoryPage />
              )}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/services"
              component={() => <ServicesPage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/providers"
              component={() => <ProvidersPage />}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/airport-terminals"
              component={() => (
                <AirportTerminalPage />
              )}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/car-operators"
              component={() => (
                <CarOperatorPage />
              )}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/car-types"
              component={() => (
                <CarTypePage />
              )}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/partners"
              component={() => (
                <PartnerPage />
              )}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/postcodes"
              component={() => (
                <PostcodeDistrictPage />
              )}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/track-bookings"
              component={() => (
                <TrackBookingPage />
              )}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              exact
              path="/manage-booking-rate"
              component={() => (
                <ManageBookingRate />
              )}
              isAuthenticated={isAuthenticated}
            />
          </Switch>
        </Layout>
      )}
    </Fragment>
  );
}

export default AppRoute;
