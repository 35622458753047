import React, { Component } from 'react';

class SpecialistPage extends Component {
    render() {
        return (
            <div className="text-dark">
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="text-info font-weight-lighter">Manage Specialist</h4>
                </div>
            </div>                 
        );
    }
}

export default SpecialistPage;