import React, { useState, useEffect } from "react";
import GenericTable from "../../components/common/GenericTable";
import DynamicForm from "../../components/common/DynamicForm";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { GetAllData } from "../../api/ApiDataServiceCategory";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading";

const CarOperatorPage = () => {
    const [initialData, setInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const addItemApiUrl = ApiURL.CreateCarOperator; //TODO: create api and sp
    const editItemApiUrl = ApiURL.EditCarOperator; //TODO: create api and sp
    const deleteItemApiUrl = ApiURL.DeleteCarOperator; //TODO: create api and sp
    const getAllItemApiUrl = ApiURL.GetCarOperators; //TODO: create api and sp

    // Table columns
    const columns = [
        { key: "OperatorId", alias: "#", isPrimaryKey: true, isVisible: false },    
        { key: "OperatorName", alias: "Operator Name", colWidth: '300px' },
        { key: "OperatorAddress", alias: "Address", colWidth: '300px' },
        { key: "OperatorPhoneNo", alias: "Phone No.", colWidth: '120px' },        
      ];
    
    // Ignore columns from search text
    const ignoredColumns = ["TerminalId"];

    // Blank object for add item
    const addingObject = {
        OperatorId: "", //PK
        OperatorName: "",
        OperatorAddress: "",
        OperatorPhoneNo: "",        
    };

    // Data model for add/edit item
    const formDataModel = [
        {
          name: "OperatorId",
          label: "Id",
          type: "text",
          required: false,
          isPrimaryKey: true,
          isReadonly: true,
          isHidden: true,
          showLabel: true,
        },
        {
          name: "OperatorName",
          label: "Operator Name",
          type: "text",
          required: true,
          showLabel: true,
        },
        {
            name: "OperatorAddress",
            label: "Address",
            type: "text",
            required: false,
            showLabel: true,
        },
        {
            name: "OperatorPhoneNo",
            label: "Phone No",
            type: "number",
            required: false,
            showLabel: true,
        },
        // {
        //     name: "IsActive",
        //     label: "Is Active",
        //     type: "checkbox",
        //     required: false,
        //     showLabel: false,
        // },
        ];

    const getAllCarOperatorsData = () => {
        GetAllData(getAllItemApiUrl)
        .then((json) => {
            setServiceError(false);
            if (json.StatusCode === 200) {
            setInitialData(json.DataResult);
            } else if (json.StatusCode === 500) {
            cogoToast.error("Internal Server Error ! Please Try Again.", {
                position: "bottom-center",
            });
            } else {
            cogoToast.error("Request Failed! Please Try Again.", {
                position: "bottom-center",
            });
            }
        })
        .catch((error) => {
            setServiceError(true);
        })
        .finally(() => {
            setLoading(false);
        });
    };
     
    useEffect(() => {
        setLoading(true);        
        getAllCarOperatorsData();
    }, [serviceError]);

    return (              
        <div className="text-dark">
            <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-info font-weight-lighter">
                Car Operators
            </h4>
            </div>
    
            {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
            {loading ? (
                <Loading />
            ) : serviceError ? (
            <ServiceUnavailable />
            ) : (
            <>
                {initialData && (
                <GenericTable
                    data={initialData}
                    columns={columns}
                    ignoredColumns={ignoredColumns}
                    enablePaging={true}
                    enableSorting={true}
                    enableSearching={true}
                    enableDeleting={true}
                    addingObject={addingObject}
                    addTitle="Add Terminal"
                    editTitle="Edit Terminal"
                    addItemApiUrl={addItemApiUrl}
                    editItemApiUrl={editItemApiUrl}
                    deleteItemApiUrl={deleteItemApiUrl}
                    getAllItemApiUrl={getAllItemApiUrl}
                    AddItemComp={DynamicForm}
                    EditItemComp={DynamicForm}
                    formDataModel={formDataModel}
                    noOfColumnsLayout={1}
                    pageSizeOptions={[10, 50, 100]}
                />
                )}
            </>
            )}
        </div> 
    );
}

export default CarOperatorPage