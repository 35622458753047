import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const SimpleDropdown = ({
    options,    
    getSelectedValue, 
    defaultRecord}) => {
    const animatedComponents = makeAnimated();
    const [selectedValue, setSelectedValue] = useState(defaultRecord);
    const [selectedText, setSelectedText] = useState(
      (options &&
        options.find((option) => option.value === defaultRecord)?.label) ||
        ""
    );
  
    useEffect(() => {    
        getSelectedValue(selectedValue, selectedText);
    }, [selectedValue, selectedText]);

    const handleDropdownSelect = (eventKey, event) => {
        // `eventKey` is the value of the selected item
        // `event` is the original event, if needed
    
        // Set the selected text in the state
        setSelectedValue(eventKey.value);
    
        // Find the corresponding text for the selected value
        const selectedOption = options.find(
          (option) => option.value === eventKey.value
        );
    
        if (selectedOption) {
          setSelectedText(selectedOption.label);
        }
      };


   return (
        <Select
          closeMenuOnSelect={true}
          isSearchable={true}
          components={animatedComponents}
          options={options.map((option) => ({
            value: option.value ?? option,
            label: option.label ?? option,
          }))}
          isMulti={false}
          onChange={handleDropdownSelect}
          defaultValue={options.filter(function (option) {
            return option.value === selectedValue | 0;
          })} 
                
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.hasValue ? "#999" : state.isFocused ? "red":"gray",
              minWidth: '160px',
              margin:'10px'
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              paddingTop: 0,
              paddingBottom: 0,
            }),
          }}
        />
    )
}

export default SimpleDropdown