import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "../../assets/css/ModalStyle.css";

const EditModal = ({ title, isOpen, closeModal, onSave, hasAcceptReject, onAcceptReject, children }) => {
    const [isAllRequiredValied, setIsAllRequiredValied] = useState(null);
   

    const handelValidateField = (isValid) => {
        setIsAllRequiredValied(isValid);
    }

    const handleSave = () => {
        if (isAllRequiredValied) {            
            onSave(children.props.editedData); // Pass the editedData from children
            closeModal();
        } else {
            alert('Please fill in all required fields.'); 
        }      
    };

  
    return (
        <>
            <Modal show={isOpen} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {React.cloneElement(children, {
                        editedData: children.props.editedData,
                        setEditedData: children.props.setEditedData,
                        hasAcceptReject: hasAcceptReject,
                        onAcceptReject: onAcceptReject,
                        formData: children.props.editedData,
                        setFormData: children.props.setEditedData,
                        validateField: handelValidateField
                    })}
                </Modal.Body>
                <Modal.Footer>
                    {!hasAcceptReject &&
                        <>
                            <Button variant="primary" onClick={handleSave} className='btn-sm btn-info small-font'>
                            <i className="fa fa-save" aria-hidden="true"></i> Save
                            </Button>
                            <Button variant="secondary" onClick={closeModal} className='btn-sm small-font'>
                            <i className="fa fa-close" aria-hidden="true"></i> Cancel
                            </Button>
                        </>
                    }

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditModal