import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Topbar from "../../components/common/Topbar";
import PageTitle from "../../components/home/PageTitle";
import Footer from "../../components/common/Footer";
import GenericTable from "../../components/common/GenericTable";
import EditProviderPage from "./EditProviderPage";
import DynamicForm from "../../components/common/DynamicForm";
import { GetAllDataByPostMethodBody } from "../../api/ApiDataServiceCategory";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading";

 
const ProvidersPage = ({onLogout}) => {
  const [initialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceError, setServiceError] = useState(false); 
  const editItemApiUrl = ApiURL.ApproveOrRejectProvider; 
  const getAllItemApiUrl = ApiURL.GetAllProviders;

  // Table columns
  const columns = [
    { key: "UserId", alias: "#", isPrimaryKey: true, isVisible: false },
    // { key: "Title", alias: "Title" },
    { key: "ItemImage", alias: "Image", type: "image-hlink", height:"54px", width:"54px", colWidth: '30px' },
    { key: "FirstName", alias: "First Name" },
    // { key: "MiddleName", alias: "Middle Name" },
    { key: "LastName", alias: "Last Name" },
    // { key: "CreatedDate", alias: "Created On" },
    // { key: "UpdatedDate", alias: "Updated On" },
    // { key: "AlternativeContactNo", alias: "Alt Contact No" },
    { key: "WPFilename", alias: "WP File Name", isHLink: true, link:"WPFilenameHLink" },
    { key: "DBSCheckFileName", alias: "DBS Check File Name", isHLink: true, link:"DBSCheckFileNameHLink" },
    {
      key: "IsApproved",
      alias: "Approved",
      alignCenter: true,
      //render: (value) => value ? "Yes" : "No",  
      render: (value) => value ? <i className="fa fa-check-square fa-2x"></i> : <i className="fa fa-square fa-2x"></i>

    },
  ];

  // Ignore columns from search text
  const ignoredColumns = ["ItemImage"];

  // Blank object for add item
  const addingObject = {
    UserId: "", //PK
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    CreatedDate: "",
    UpdatedDate: "",
    AlternativeContactNo: "",
    WPFilename: "",
    DBSCheckFileName: "",
    ItemImage: "",
    IsApproved: "",
  };
 
  // Data model for add/edit item
  const formDataModel = [
    { name: "name", label: "Name", type: "text", required: true },
    { name: "email", label: "Email", type: "email", required: true },
    { name: "age", label: "Age", type: "number", required: false },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      options: ["Male", "Female", "Other"],
      required: true,
    },
    {
      name: "subscribe",
      label: "Subscribe",
      type: "checkbox",
      required: false,
    },
    {
      name: "profileImage",
      label: "Profile Image",
      type: "image",
      required: true,
    },
    // { name: "birthdate", label: "Birthdate", type: "datetime", required: true },
  ];

  const getAllProvidersData = () => {
    let reqData = {
      searchText: "",
    };

    GetAllDataByPostMethodBody(getAllItemApiUrl, reqData)
      .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
          setInitialData(json.DataResult);
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        setServiceError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllProvidersData();
  }, [serviceError]);

  return ( 
    <div className="text-dark">
      {/* Modal form for adding item */}
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-info font-weight-lighter">Providers</h4>
      </div>

      {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
      {loading ? (
          <Loading />
      ) : serviceError ? (
        <ServiceUnavailable />
      ) : (
        <>
          {initialData && (
            <GenericTable
              data={initialData}
              columns={columns}
              enablePaging={true}
              enableSorting={true}
              enableSearching={true}  
              enableDeleting={false}    
              editItemApiUrl={editItemApiUrl}                      
              getAllItemApiUrl={getAllItemApiUrl}
              editTitle="Approve/Reject to Provider"
              EditItemComp={DynamicForm}
              hasAcceptReject={true}
              noOfColumnsLayout={2}
              pageSizeOptions={[10, 50, 100]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProvidersPage;
